// /*jshint esversion: 5.1 */
// /*global $: false */
// //TODO make this available everywhere
// //TODO Make Angular be able to access this
// class ContentPage {
//     constructor() {
//      // $('#js-favorite-button').click(this.toggleFavorite);
//        //$('.js-tag-follow').click(this.toggleTag);
//     }
//
// //     toggleTag() {
// //           let $this = $(this),
// //              $panel = $this.closest('.tag-button'),
// //              action = $panel.hasClass('is-favorite') ? 'del' : 'add';
// //           $.get('/bin/bhgre/tags.' + action + '.json', {tag: $this.data('tag')})
// //              .done(() => {
// //                 if (action === 'add') {
// //                    $panel.addClass('is-favorite').find('.fa').addClass('fa-star').removeClass('fa-star-o');
// //                 } else {
// //                    $panel.removeClass('is-favorite').find('.fa').addClass('fa-star-o').removeClass('fa-star');
// //                 }
// //
// //                 // if (action === 'add') {
// //                 //    $this.addClass('is-favorite').find('.fa').addClass('fa-star').removeClass('fa-star-o');
// //                 // } else {
// //                 //    $this.removeClass('is-favorite').find('.fa').addClass('fa-star-o').removeClass('fa-star');
// //                 // }
// //
// //              })
//              .fail(() => {
//                 alert('An error occurred setting favorite.');
//              });
//     }
//
//     toggleFavorite() {
//         let $this = $(this),
//            action = $this.hasClass('is-favorite') ? 'del' : 'add';
//         $.get('/bin/bhgre/favorites.' + action + '.json', {url: window.__currentPage.favoriteUrl})
//             .done(() => {
//                 if (action === 'add') {
//                    $this.addClass('is-favorite');
//                 } else {
//                    $this.removeClass('is-favorite');
//                 }
//             })
//             .fail(() => {
//                alert('An error occurred setting favorite.');
//             });
//     }
// }

//$(document).ready(function(){new ContentPage()});